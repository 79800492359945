<template>
    <BaseLayout>
        <template v-slot:page-content>
            <div class="container-fluid">
                <BreadCrumb :current-page="$t('nav.favourite')"/>
            </div>
            <div class="all favourite_products" >
                <section class="container-fluid px-lg-4 custom-wrapper">
                    <div class="mr-custom ml-custom">
                        <h2 class="sub-header">{{ $t('nav.favourite') }}</h2>


                        <div class="row" v-if="favoriteProducts.length">
                            <div v-for="product in favoriteProducts" :key="product.id"
                                 class="col-lg-2 col-md-4 col-sm-6 col-12"  data-aos="fade-up" data-aos-duration="800">
                                <ProductCard :product="product"/>
                            </div>
                        </div>

                        <EmptyFavorite v-else/>
                    </div>
                </section>
            </div>



        </template>
    </BaseLayout>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import BaseLayout from "@/containers/BaseLayout";
import ProductCard from "@/components/ProductCard";
import EmptyFavorite from "@/components/EmptyFavorite";
import {mapGetters} from "vuex";

export default {
    name: 'Favourite',
    components: {ProductCard, BaseLayout, BreadCrumb,EmptyFavorite},
    data() {
        return {

        }
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            favoriteProducts:"favoriteProducts"
        }),
    },
}
</script>

<style>


</style>