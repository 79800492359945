<template>
<div class="container-fluid text-center px-lg-4 custom-wrapper empty-box">
    <img src="@/assets/img/empty_fav.svg" class="empty" alt="">
    <h4 class="text-center empty_text">{{ $t('product.cart-empty')}}</h4>
</div>
</template>

<script>
export default {
    name: 'Empty_favorite'
}
</script>


<style>



</style>