<template>
    <div class="card w-100 custom-card">
        <button class="btn btn-favourite shadow" @click="toggleFavorite" :disabled="load_fav">
            <span class="sr-only">Favourite product</span>
            <i class="fas fa-heart " :class="{'is_favorite' : is_fav}"></i>
        </button>
        <div class="spinner-grow text-danger btn-favourite " v-if="load_fav" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <router-link :to="{ name: 'Product', params: { slug: product.id }}">
<!--            <img class="card-img-top product-image" :src="product.Media[0].file" :alt="product.name">-->
            <div  v-if="product.Media[0].file.slice(product.Media[0].file.lastIndexOf('.')) === '.mp4'">
                <video  class="card-img-top product-image" width="100" height="80" controls>
                    <source :src="product.Media[0].file" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
            <div v-else>
                <img :src="product.Media[0].file"  class="card-img-top product-image" alt="">
            </div>
        </router-link>
        <div class="card-body">
            <h1 style="font-size: 15px;">
                <a href="" class="float-left">{{ product.product_no }}</a>{{ product.name }}
            </h1>
            <p class="stars">
                <i class="fas fa-star"></i> <a>{{ Number(product.rate).toFixed(1) }}</a>
            </p>
            <p class="price">{{  Number(product.size_price[0].price).toFixed(1) }} {{ currency.coin?.name }}
                <router-link :to="{ name: 'Product', params: { slug: product.id }}" class="float-left">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>

import {toggle_favorite} from "@/services/products";
import store from "@/store";

export default {
    name: 'ProductCard',
    props: ['product'],
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            is_fav: this.product.is_favorite,
            load_fav: false
        }
    },
    mounted() {
        this.checkIfFav();
    },
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        API_URL: function () {
            return process.env.VUE_APP_REMOTE_URL
        },
        currency: function () {
            return store.getters.getCurrency;
        }
    },
    methods: {
        checkIfFav() {
            let favorites = store.getters.favoriteProducts
            let idx = favorites.findIndex(product => product.id === this.product.id);
            this.is_fav = idx !== -1;
        },
        toggleFavorite() {
            if (this.$store.getters.user.loggedIn) {
                this.load_fav = true
                toggle_favorite({"product_id": String(this.product.id)})
                    .then(res => {
                        if (res.status.code === 401) {
                            localStorage.removeItem('access_token')
                            this.$router.push('/login')
                            this.$store.dispatch("fetchUser");
                        } else if (res.data.status.code === 422) {
                            this.load_fav = false;
                            return
                        }
                        this.is_fav = res.data.Product.is_favorite;
                        store.dispatch("toggleFav", res.data.Product);
                        this.load_fav = false
                    })
            } else {
                this.$router.push('/login')
            }
        }
    }
}
</script>

<style scoped>
.custom-card {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.product-image {
    border-bottom: 1px solid #dedbdb;
    height: 160px;
    object-fit: cover;
    object-position: center;
}
</style>